import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105')
];

export const server_loads = [3,2,7,5,6];

export const dictionary = {
		"/(frontend)": [~26,[3]],
		"/(frontend)/archiv": [66,[3]],
		"/(frontend)/(content-routes)/chat": [~27,[3]],
		"/(frontend)/(content-routes)/detail/announcement/[id]": [~28,[3]],
		"/(frontend)/(content-routes)/detail/document/companyAgreement/[id]": [~29,[3]],
		"/(frontend)/(content-routes)/detail/document/correspondence/[id]": [~30,[3]],
		"/(frontend)/(content-routes)/detail/document/file/[id]": [~31,[3]],
		"/(frontend)/(content-routes)/detail/document/general/[id]": [~32,[3]],
		"/(frontend)/(content-routes)/detail/document/invitation/[id]": [~33,[3]],
		"/(frontend)/(content-routes)/detail/document/protocol/[id]": [~34,[3]],
		"/(frontend)/(content-routes)/detail/document/resolution/[id]": [~35,[3]],
		"/(frontend)/(content-routes)/detail/document/wageAgreement/[id]": [~36,[3]],
		"/(frontend)/(content-routes)/detail/event/[id]": [~37,[3]],
		"/(frontend)/(content-routes)/detail/notification": [~38,[3]],
		"/(frontend)/(content-routes)/detail/phoneCall/[id]": [~39,[3]],
		"/(frontend)/(content-routes)/detail/role/[id]": [~40,[3]],
		"/(frontend)/(content-routes)/detail/task/[id]": [~41,[3]],
		"/(frontend)/(content-routes)/detail/user/[id]": [~42,[3]],
		"/(development)/dev": [9,[2]],
		"/(development)/dev/accordion": [10,[2]],
		"/(development)/dev/addmenu": [11,[2]],
		"/(development)/dev/alerts": [12,[2]],
		"/(development)/dev/api-test": [~13,[2]],
		"/(development)/dev/assistant": [~14,[2]],
		"/(development)/dev/buttons": [15,[2]],
		"/(development)/dev/cards": [16,[2]],
		"/(development)/dev/dialog": [~17,[2]],
		"/(development)/dev/form-multi-page": [~18,[2]],
		"/(development)/dev/icons": [19,[2]],
		"/(development)/dev/inputs": [~20,[2]],
		"/(development)/dev/lists": [21,[2]],
		"/(development)/dev/menus": [22,[2]],
		"/(development)/dev/tabs": [23,[2]],
		"/(development)/dev/various": [24,[2]],
		"/(development)/dev/widgets": [25,[2]],
		"/einrichtungsassistent": [~105,[8]],
		"/(frontend)/(content-routes)/forms/announcement/[[id]]": [~43,[3]],
		"/(frontend)/(content-routes)/forms/documentFile/[[id]]": [~51,[3]],
		"/(frontend)/(content-routes)/forms/document/companyAgreement/[[id]]": [~44,[3]],
		"/(frontend)/(content-routes)/forms/document/correspondence/[[id]]": [~45,[3]],
		"/(frontend)/(content-routes)/forms/document/general/[[id]]": [~46,[3]],
		"/(frontend)/(content-routes)/forms/document/invitation/[[id]]": [~47,[3]],
		"/(frontend)/(content-routes)/forms/document/protocol/[[id]]": [~48,[3]],
		"/(frontend)/(content-routes)/forms/document/resolution/[[id]]": [~49,[3]],
		"/(frontend)/(content-routes)/forms/document/wageAgreement/[[id]]": [~50,[3]],
		"/(frontend)/(content-routes)/forms/event/[[id]]": [~52,[3]],
		"/(frontend)/(content-routes)/forms/generalDocument/[[id]]": [~53,[3]],
		"/(frontend)/(content-routes)/forms/mandator": [~54,[3]],
		"/(frontend)/(content-routes)/forms/phoneCall/[[id]]": [~55,[3]],
		"/(frontend)/(content-routes)/forms/photoAdd/[id]": [~56,[3]],
		"/(frontend)/(content-routes)/forms/photoDocumentAdd": [~57,[3]],
		"/(frontend)/(content-routes)/forms/photoDocumentEdit/[id]": [~58,[3]],
		"/(frontend)/(content-routes)/forms/protocolDocument/[[id]]": [~59,[3]],
		"/(frontend)/(content-routes)/forms/role/[[id]]": [~60,[3]],
		"/(frontend)/(content-routes)/forms/search": [~61,[3]],
		"/(frontend)/(content-routes)/forms/task/[[id]]": [~62,[3]],
		"/(frontend)/(content-routes)/forms/team/[[id]]": [~63,[3]],
		"/(frontend)/(content-routes)/forms/user/settings": [~65,[3]],
		"/(frontend)/(content-routes)/forms/user/[[id]]": [~64,[3]],
		"/(login)/login": [~100,[7]],
		"/(login)/login/2fa": [~101,[7]],
		"/(login)/logout": [~102,[7]],
		"/(frontend)/meine-arbeit": [~67,[3,4]],
		"/(frontend)/meine-arbeit/aufgaben": [~68,[3,4]],
		"/(frontend)/meine-arbeit/dokumente": [~69,[3,4]],
		"/(frontend)/meine-arbeit/dokumente/allgemein": [~70,[3,4]],
		"/(frontend)/meine-arbeit/dokumente/einladungen": [~71,[3,4]],
		"/(frontend)/meine-arbeit/dokumente/protokolle": [~72,[3,4]],
		"/(frontend)/meine-arbeit/dokumente/schriftwechsel": [~73,[3,4]],
		"/(frontend)/meine-arbeit/telefonliste": [~74,[3,4]],
		"/(frontend)/meine-arbeit/termine": [~75,[3,4]],
		"/(login)/password-forgot": [~103,[7]],
		"/(login)/password-reset/[token]": [~104,[7]],
		"/(frontend)/profil": [~76,[3]],
		"/(frontend)/sekretariat": [~77,[3,5]],
		"/(frontend)/sekretariat/ankuendigungen": [~78,[3,5]],
		"/(frontend)/sekretariat/berichte": [79,[3,5]],
		"/(frontend)/sekretariat/einstellungen": [~80,[3,5]],
		"/(frontend)/sekretariat/funktionen": [~81,[3,5]],
		"/(frontend)/sekretariat/personen": [~82,[3,5]],
		"/(frontend)/sekretariat/teams": [~83,[3,5]],
		"/(frontend)/team/[id]": [~84,[3,6]],
		"/(frontend)/team/[id]/aufgaben": [~85,[3,6]],
		"/(frontend)/team/[id]/dokumente": [~86,[3,6]],
		"/(frontend)/team/[id]/dokumente/allgemein": [~87,[3,6]],
		"/(frontend)/team/[id]/dokumente/beschluesse": [~88,[3,6]],
		"/(frontend)/team/[id]/dokumente/betriebsvereinbarungen": [~89,[3,6]],
		"/(frontend)/team/[id]/dokumente/einladungen": [~90,[3,6]],
		"/(frontend)/team/[id]/dokumente/protokolle": [~91,[3,6]],
		"/(frontend)/team/[id]/dokumente/schriftwechsel": [~92,[3,6]],
		"/(frontend)/team/[id]/dokumente/tarifvertraege": [~93,[3,6]],
		"/(frontend)/team/[id]/fotos": [~94,[3,6]],
		"/(frontend)/team/[id]/fotos/[documentId]": [~95,[3,6]],
		"/(frontend)/team/[id]/mitglieder": [~96,[3,6]],
		"/(frontend)/team/[id]/sitzungen": [97,[3,6]],
		"/(frontend)/team/[id]/telefonliste": [~98,[3,6]],
		"/(frontend)/team/[id]/termine": [~99,[3,6]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';